@include colorBase();

$main-theme-color: #4DA8FB;
$invite-others-link: #fff;
$btn-link-color:  #fff;
$btn-link-color-hover:  #dedede;
$btn-round-color:  #fff ;
$btn-round-color-hover:  #dedede;
$main-background-color-new: #F3F3E9;
$main-background-hover:	#FFA806;
$selected: #cc6a66;
$datepicker-color: #333;

// Icon
$start-sprint-icon-color: #777467;
$file-upload-icon: $main-theme-color;
$uploadLabel:#ccc ;
$uploadLabel-hover:linear-gradient(to bottom, #306a99, #306a99) ;
$uploadLabel-active:linear-gradient(to bottom, #bbb, #bbb) ;

// Button 
$add-comment-btn: #4283ad;

// Placeholder Color
$dynamic-theme-placeholder-color:  #bdbdbd;
$dynamic-theme-placeholder-date: #000;

// Comment
$comment-editor-background: #8d8175;
$system-operation-comment-background: $comment-editor-background;

// Toggle
$toggle-color: #333;
$toggle-hover: #ffdd9f;
$toggle-active: #f2c94c;
$toggle-select: #f2c94c;
$toggle-hover-opacity: rgba(255, 221, 159, 0.5);

// Dropdown 
// $dropdown-hover-bg-color: lighten($main-background-hover, 20%);

// Tab 
$tab-color: #fff;
$tab-active: #fff;
$tab-background-active: #4DA8FB;
$tab-background-hover: #ffce66;

// Dynamic
$dynamic-thead-color: #333;
$dynamic-thead-background: #eee;
$dynamic-thead-background-active: #e3e3e3;
$dynamic-dropdown-menu-active: #4da8f8;
$dynamic-icon-btn-color: #fff;
$dynamic-icon-btn-hover: transparent;
$dynamic-dd-list-bottom-background: $main-background-color-new;
$dynamic-link-hover-color: rgba(33, 131, 128, 0.8);

// Datepicker
$theme-datepicker-color:#ffce66;
$theme-datepicker-hover: #ffdd9f;

$datepicker-active: $theme-datepicker-color;
$datepicker-hover: $theme-datepicker-hover;
$datepicker-day-active: $theme-datepicker-color;
$datepicker-day-hover: $theme-datepicker-hover;

// Label 
// $label-on: #ffc65b ;
// $label-on-color: #fff;



// Modal  
$modal-information-header-bg: #4DA8FB; 
$modal-information-header-color: #fff;
$information-header-bg: $modal-information-header-bg;

// Modal Main System Operation 
$modal-header-bg: #D3D3D3;
$modal-header-color: #333;
$modal-nav-option-bg: #ececec;
$modal-nav-option-hover: $main-theme-color;
$modal-nav-option-active: #347ab6;   
$modal-nav-option-color: #333; 


// Nav 
$navBarColor:  #3C89CE ; 
$navSidebarColor: #E6E6E6;
$navBar-selected: #347AB6;
$nav-sidebar-background-hover:  $main-theme-color;
$nav-sidebar-background-seleted:  $main-theme-color;
$nav-sidebar-icon-color:  rgba(99, 98, 98, 0.76); 
$nav-sidebar-icon-hover: $white;

$nav-member-title-background: $main-theme-color ;
$nav-member-title-color:  $white;


// Profile
$profile-nav-tabs-color: #337ab7;
$profile-nav-tabs-hover: #ffce66;
$profile-nav-tabs-active: $main-theme-color;
$profile-nav-tabs-active-color: #fff;


// SideBar
$notice-header-background: $main-theme-color;
$notice-header-color: $white;

$notice-filter-background: $white;
$notice-filter-mobile-background: #fff;
$notice-filter-mobile-color: rgb(150,150,150);
$notice-filter-color: #333333;

$notice-main-background: #efedee;
$notice-mobile-background: #efedee; 
$notice-main-color: #474438;

$notice-card-background: $white;
$notice-card-background-hover: #E0E0E0;
$notice-card-color: #808080;

$notice-tab-background: #87c8ff;
$notice-tab-active: #3c89ce;
$notice-tab-hover: #347ab6;

$projectSetting-menu-header-bg: $main-theme-color;
$projectSetting-menu-header-color: $white;

$projectSetting-content-bg: $white;
$projectSetting-content-color: black;
$projectSetting-input-color: black;
$projectSetting-filter-border: #e0e0e0;
$projectSetting-filter-bg:  transparent;
$projectSetting-filter-color: black;
$projectSetting-filter-input: #333;

$calendar-backgound: #eee;

$filter-block-dropdown-open: #e3e3e3;
$filter-block-background: #fff;

// MobileMenu Panel
$mobileMenu-main:#F3F3E9; 
$mobileMenu-panel-project-background: #cee8ef;
$mobileMenu-panel-todo-background: #fcf1be ;
$mobileMenu-panel-backlog-background: #efd9f4;
$mobileMenu-panel-review-background: #f9c9c7;
$mobileMenu-panel-effort-background: ghostwhite;
$mobileMenu-panel-managed-background: ghostwhite;

$otis-card-background: rgb(227, 227, 227);
$otis-card-body-background: white;
 

// Todo 
$todo-nav-option-bg: #2287e2;
$todo-nav-option-hover: $main-theme-color;
$todo-nav-option-active: $navBarColor; 
$todo-nav-option-color: $white; 






// Backlog 
$filter-factor-color: rgba(0,0,0,0.3);
$filter-factor-selected-background: rgba(0,0,0,0.05);
$filter-factor-selected-color: rgba(0,0,0,1);
$backlog-page-title-toggle: #ffdd9f;
$backlog-page-title-active: #ffce66;

// File View
$fileView-hover-background: #eee;
$folder-row-selected: #3c89ce;
$folder-row-hover: #b1daff ;
$folder-row-active: #4da8f8 ;
$folder-row-active-color: #fff;
$edit-icon-hover: #4fa6f5;
$edit-icon-active: #3c89ce;

$fileView-filter-hover:  $toggle-hover;
$fileView-filter-color:  $toggle-color;


// Discussion
$discussion-panel-selected-background: #b1daff;
$discussion-panel-selected-color: #333;
// $discussion-panel-background-hover: $main-theme-color;
$discussion-panel-background-hover: #eee;
$discussion-panel-color-hover: #333;;

$discussion-topic-odd-panel: #fff; 
$discussion-topic-event-panel: #fff; 

$discussion-sel-topic-title-left: $main-theme-color;
$discussion-sel-topic-title: #b1daff;

$discussion-topic-add-button: $btn-primary;
$discussion-topic-add-button-border: $btn-primary-border;

$discussion-comment-editor-info: #fff;
$info-assignment-color: #333;
$edit-comment-color: #333;
$cmt-editor-btn-color: $bootstrap-primary;
$modal-editing-cmt-info-color: #333;
  
// Gantt 
$gantt-table-header: $main-theme-color;
$gantt-table-side-col: #E6E6E6;
$gantt-table-row-select: #eeeeee;
$gantt-table-row-hover: #b1daff;
$gantt-table-row-color: #333;

$gantt-date-header: $main-theme-color ;

// List View
$list-filter-toggle-hover: #b1daff;


// ================== Old color ============== //
$mainColor: rgb(239,238,230);
$main-text: darken(rgb(71,68,56), 20%);
$main-text-hover:rgb(38,170,226);
$overlay-color: rgba(10,10,10,0.9);
$dropDrown-color:rgb(115, 115, 115);
$projectCardHover-background:	#e9e0d2;
$projectCardWatchIcon-color:  #9e8b75;
$sprintTitleOpition-hoverColor: $dropDrown-color; 

$main-background-color:  rgb(244,243,233);
$main-background-focus: darken(#ffbe33, 10%);
$even-background-color: #ffffff;
$odd-background-color:  #ffffff;
$addSprint-text-color: $dropDrown-color;
$noticeBackground: rgb(129,217,219);
$noticeCardBackground:rgb(89,133,134); 
$myTodoListHeaderColor:rgb(19,85,137);


$fileViewHover-border-color: darken(#cce8ff, 15%);
$fileView-selected-background: #cce8ff;

$header-text: #ffffff;
$projectBodyBackground: transparent;
$viewTitleBackground: lighten(rgba(230,230,230,1),0%);
$viewTitleTextColor: $main-text;
$viewTitleTextHovered: $warm;
$inverseViewTitleTextColor: white;

$addProjectCardColor: darken(rgb(247,245,242),30%);
$recentProjectCardColor:#fff;
$joinedProjectCardColor: rgb(156,210,217);
$archivedProjectCardColor: lighten(rgb(97,99,99),5%);

$activeSprintColor: rgb(158,216,216);
$generalSprintColor: rgb(229,226,209);
$finishedSprintColor: rgb(172, 172, 168);
$uncalssifiedBacklogColor: rgb(55,188,191);
$projectTplBackground: rgb(55,188,191);

$doingSprintColor: darken(#F8D374, 10%);
$sprintViewBackground: rgba(255,255,255,0);
$sprintDetailColor:	rgb(229,226,209);
$doingTodoColor : rgb(255,222,118);
$memberViewColor: rgb(158,216,216);
$memberViewText-hover:gold;

$memberViewBacklogColor: rgb(55,188,191);

$unStartSprintLight: 0%;//forSprintPageNotyetStart
$unStartSprintDark: 0%;
$phaseViewBottomPercent : 0%;
$memberViewBottomPercent: 0%;
$memberViewTodoCardTextColor: #fff;

$todoListBorderStyle: 1px solid rgba(119,116,103,0.4);
$waitTodoListBkColor: rgb(55,188,191);
$waitTodoListBkColorHeader: $waitTodoListBkColor;
$waitTodoListBkColorBottom: $waitTodoListBkColor;
$waitTodoListBkColorContent: #fff;
$waitTodoListTextColor: $main-text;

$doingTodoListBkColor:rgb(255,222,118);
$doingTodoListBkColorHeader: $doingTodoListBkColor;
$doingTodoListBkColorBottom: $doingTodoListBkColor;
$doingTodoListBkColorContent: #fff;
$doingTodoListTextColor: $main-text;

$haveDoneListBkColor: rgb(229,226,209);
$haveDoneListBkColorHeader: $haveDoneListBkColor;
$haveDoneListBkColorBottom:	$haveDoneListBkColor;
$haveDoneListBkColorContent: $haveDoneListBkColor;
$haveDoneListTextColor: rgba(119,116,103,1);

$titleBarColor: rgb(229,226,209);
$listHeaderGeneral: $titleBarColor;
$otisLeadColor: rgb(60,206,202);
$myselfIconBkColor: rgb(60,206,202);
$memberIconBkColor: rgb(119,116,103);
$clientIconBkColor: rgb(229,226,209);
$memberColor: #ffffff;
$pmColor: rgb(255,222,118) ;
$plColor: rgb(255,240,176);

$projectInfoActive-background:  $main-text;
$projectInfoActive-color:white;
$projectInfoTab-color:$dropDrown-color;
$projectInfoActive-background-hover:gold;


$todoCommentEditorBackground:  rgba(71,68,56,1);

$body-background: rgb(71,68,56);
$dynamicBackground: rgba(55,188,191,1);
$dynamicBackgroundTransparent: rgba(55,188,191,0.9);
$dynamicBackgroundDarker: #efeee6cc;
// $dynamicBackgroundDarker: rgba($mainColor,0.8);
// $dynamicBackgroundSolid: rgba($mainColor,1); 
$dynamicLinkColor: rgb(60,206,202);
$dynamicOpacityFactor: 1;
$dynamicReportBackground: rgba(0,0,0,0);
$dynamicPlaceHolderColor: lighten($viewTitleTextColor, 5%);
$dynamicTextColor-dark: gray;
$dynamicToggledColor: red;
$dynamicColorGray: gray;


$backlogOpacityFactor: 10%;

$backgroundImg: "";
$backgroundImgSprint: "../../assets/img/otis-defined/otisBkImgLight02.jpg_remove";
$selectArrow: "../../assets/img/otis-defined/arrow-B.png";
$sprintBackgroundColor: #e1dad0;
$discussionColor: #37bcbf;
$discussionChatColor: lighten(beige, 10%);
$discussionBtnColor: #ff9600;
$discussionTimeColor:rgba(37,37,37,0.6);
$commentsHyperlinkColor: steelblue;

$calendarBorderColor: $main-background-color;
$reportSubTitle: darkorange;
$reportEffort: lightseagreen;

$listViewFilterPanelBackground: rgba(255,255,255,0.3);
$backlogViewFilterPanelBackground: rgba(lighten( $navSidebarColor, 20%),1);